import React, {useState,useContext} from 'react';
import {AuthContext} from "../context/auth.context";
import {usePayment} from "../hooks/payment.hook";
import {useCallback, useEffect} from "react";

export const AdminPayments = () =>{
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [approvedPayments, setApprovedPayments] = useState([]);
    const {getPaymentHistory} = usePayment();


    const fetchPaymentHistory = async  () =>{
        let history = await getPaymentHistory();
        history = history.reverse();

        let _approvedPayments = [];
        for(let i=0;i<history.length;i++)
        {
            if(history[i].isOrderConfirmationReceived && history[i].isPaymentConfirmed)
                _approvedPayments.push(history[i]);
        }

        setApprovedPayments(_approvedPayments);
        setPaymentHistory(history);
    }

    const pageLoaded = async ()=>{
        fetchPaymentHistory();
    }

    useEffect(()=>{
        pageLoaded();
    },[]);


    const printPaymentStatus =  (paymentOrder) =>{

        let visual={
            message:"",
            icon:"",
            color:""
        }

        let confirmedVisual = {
            message:"",
            icon:"check_box",
            color:"green"
        }

        let failVisual = {
            message:"",
            icon:"check_box",
            color:"red"
        }

        let waitVisual = {
            message:"",
            icon:"sync",
            color:"#ff9810"
        }

        if(paymentOrder.isLinkGenerationFailed)
        {
            visual = failVisual;
            visual.message = "redirect fail";
        }
        else
        {
            if(paymentOrder.isOrderConfirmationReceived)
            {
                if(paymentOrder.isPaymentConfirmed)
                {
                    visual = confirmedVisual;
                    visual.message = "";
                }
                else
                {
                    visual = failVisual;
                    visual.message = "Declined";
                }
            }
            else
            {
                if(paymentOrder.type === "Idram")
                {
                    visual = failVisual;
                    visual.message = "Canceled";
                }
                else if(paymentOrder.type === "Arca")
                {
                    visual = waitVisual;
                    visual.message = "Waiting ...";
                }

            }
        }

        return (
            <>
                <i className="material-icons dp48"  style={{color:visual.color,verticalAlign:"middle"}}>{visual.icon}</i>
                {visual.message}
            </>


        )
    }

    const drawPaymentTable = (title, data) =>{
        return (
            <>
                <div className="section">
                    <h5>{title}</h5>
                    <table>
                        <thead>
                        <tr>
                            <th>User</th>
                            <th>Payer</th>
                            <th>Payment ID</th>
                            <th>Date</th>
                            <th>Months</th>
                            <th>Total AMD</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((paymentInfo,index) =>{

                            return (
                                <tr key={index}>
                                    <td>{paymentInfo.user? paymentInfo.user.email:"deleted user"}</td>
                                    <td>{paymentInfo.payerUser? paymentInfo.payerUser.email:""}</td>
                                    <td>{paymentInfo.index}</td>
                                    <td>{new Date(paymentInfo.dateCreated).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })}</td>
                                    <td>{paymentInfo.amount}</td>
                                    <td>{paymentInfo.priceTotal}</td>
                                    <td style={{fontSize:"11px"}}>{printPaymentStatus(paymentInfo)}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <div className="divider"></div>
            </>
        )
    }

    return(
        <>
            {drawPaymentTable("Confirmed Payments", approvedPayments)}
            {drawPaymentTable("All Payments", paymentHistory)}
        </>
    );
}